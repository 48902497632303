import { createApp } from 'vue'
import App from '@/App.vue'
import Vuex from 'vuex';

const app = createApp(App);
app.use(Vuex);

const store = new Vuex.Store({
  state: {
    userInfo: {
    },
  },
});

export default store;
<template>
  <section class="userblock">
  <a-dropdown>
    <a class="ant-dropdown-link" @click.prevent>
      <img class="user-photo" :src="userphoto" height="40" width="40">
      <b>{{username}}</b>
    </a>
    <template #overlay>
      <a-menu>
        <a-menu-item>
          <router-link to="/account">Account Settings</router-link>
        </a-menu-item>
        <a-menu-item>
          <a href="javascript:;" @click="logout">Logout</a>
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
</section>
</template>
<script setup>
import { ref, onMounted } from "vue";
import axios from 'axios';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const router = useRouter();
const store = useStore();
const userphoto = ref('https://www.fineshare.com/images/ic-user-photo.svg');
const username = ref('');


const logout=()=>{
    localStorage.clear();
    store.state.userInfo =''
    router.push({ name: 'login'});
    document.cookie = "_FINESHARE_ID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

const getcookie = function(cname){//获取cookie
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i=0; i<ca.length; i++) 
    {
      let c = ca[i].trim();
      if (c.indexOf(name)==0) return c.substring(name.length,c.length);
    }
    return "";
  }

const getuserinfo = (tokenid) => {
            axios.get('https://moo.fineshare.com/api/getuserinfo',
                {
                    headers: {
                        'Authorization': 'Bearer ' + tokenid,
                    }
                })
                .then(response => {
                    let userInfo = {
                        isLogin: true,
                        email: response.data.email,
                        name: response.data.name,
                        token: tokenid,
                        headUrl:response.data.headUrl,
                    }
                    store.state.userInfo = userInfo;
                    username.value = response.data.name;
                })
                .catch(error => {
                    console.log(error.data);
                });
        }

const setphoto=()=>{
  if(store.state.userInfo.headUrl != null){
    userphoto.value = store.state.userInfo.headUrl;
  }else{
    userphoto.value = 'https://www.fineshare.com/images/ic-user-photo.svg';
  }
}

onMounted(()=>{
  const usertoke = getcookie("_FINESHARE_ID");
  if(usertoke){
    getuserinfo(usertoke);
  }else{
    localStorage.clear();
    store.state.userInfo =''
    router.push({ name: 'login'});
    document.cookie = "_FINESHARE_ID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }
  setphoto();
})

</script>
<style scoped>
.userblock{
  padding:30px;
  display: flex;
  justify-content: flex-end;
}
.userblock .user-photo{
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 50px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  font-weight: 18px;
  line-height: 32px;
}
.userblock b{
  font-size: 16px;
  display: inline-block;
  padding: 0 8px;
}
.userblock .ant-dropdown-menu{
  margin: 15px 0 0 0!important;
}
.ant-dropdown-link{
  display: flex;
  align-items: center;
  color: #000;
}
</style>

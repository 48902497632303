<script>
import { useRouter } from "vue-router";
import { mapState } from 'vuex';
export default {
  name: "App",
  mounted(){
  
  }
};
</script>

<template>
  <router-view class="main"></router-view>
</template>
<style>
.ant-layout-sider {
  background: none;
}
</style>

//1,从下载的vue-router中导入createRouter
import { createRouter, createWebHistory, createWebHashHistory } from "vue-router"
//3,导入引入的组件

//3,配置映射关系
const routes = [
  //默认
  {
    path: '/',
    //重定向
    redirect: "/login",
    meta: {
			title: 'Fineshare Account Center'
		}
  },
  {//首页
    path: "/dashboard",
    name:"dashboard",
    components:{
      default:() => import("@/components/page/home.vue"),
      leftnav:() =>import("@/components/page/nav.vue"),
    },
    meta: {
			title: 'Fineshare Account Center'
		}
  },
  {//登录
    path: "/login",
    name:"login",
    component: () => import("@/components/login/login.vue"),
    meta: {
			title: 'Login - Fineshare Account Center'
		}
  },
  {//登录
    path: "/user",
    name:"user",
    component: () => import("@/components/login/user.vue"),
    meta: {
			title: 'Login - Fineshare Account Center'
		}
  },
  {//注册
    path: "/signup",
    name:"signup",
    component: () => import("@/components/login/signup.vue"),
    meta: {
			title: 'Signup - Fineshare Account Center'
		}
  },
  {//注册
    path: "/signupapp",
    name:"signupapp",
    component: () => import("@/components/login/signupapp.vue"),
    meta: {
			title: 'login - Fineshare Account Center'
		}
  },
  {//移动端登录
    path: "/mobilelogin",
    name:"mobilelogin",
    component: () => import("@/components/login/mobilelogin.vue"),
    meta: {
			title: 'Signup - Fineshare Account Center'
		}
  },
  {//移动端注册
    path: "/mobilesingup",
    name:"mobilesingup",
    component: () => import("@/components/login/mobilesingup.vue"),
    meta: {
			title: 'Signup - Fineshare Account Center'
		}
  },
  {//验证邮箱
    path: "/verification",
    name:"verification",
    component: () => import("@/components/login/verification.vue"),
    meta: {
			title: 'Verification - Fineshare Account Center'
		}
  },
  {//验证邮箱
    path: "/verificationapp",
    name:"verificationapp",
    component: () => import("@/components/login/verificationapp.vue"),
    meta: {
			title: 'Verification - Fineshare Account Center'
		}
  },
  {//忘记密码
    path: "/forgot",
    name:"forgot",
    component: () => import("@/components/login/forgot.vue"),
    meta: {
			title: 'Forgot Password- Fineshare Account Center'
		}
  },
  {//重置密码
    path: "/reset",
    name:"reset",
    component: () => import("@/components/login/reset.vue"),
    meta: {
			title: 'Reset Password - Fineshare Account Center'
		}
  },
  {//注册成功
    path: "/success",
    name:"success",
    component: () => import("@/components/login/success.vue"),
    meta: {
			title: 'Success - Fineshare Account Center'
		}
  },
  {//登录成功
    path: "/successapp",
    name:"successapp",
    component: () => import("@/components/login/successapp.vue"),
    meta: {
			title: 'Success - Fineshare Account Center'
		}
  },
  {//登录成功
    path: "/authsuccess",
    name:"authsuccess",
    component: () => import("@/components/page/auth-success.vue"),
    meta: {
			title: 'Success - Successful authorization'
		}
  },
  {//用户中心首页
    path: "/home",
    name:"home",
    components:{
      default:() => import("@/components/page/home.vue"),
      leftnav:() =>import("@/components/page/nav.vue"),
    },
    meta: {
			title: 'Fineshare Account Center'
		}
  },
  {//用户信息
    path: "/account",
    name:"account",
    components:{
      default:() => import("@/components/page/account.vue"),
      leftnav:() =>import("@/components/page/nav.vue"),
    },
    meta: {
			title: 'Account Settings - Fineshare Account Center'
		}
  },
  {//用户订单
    path: "/order",
    name:"order",
    meta: { keepAlive: true },
    components:{
      default:() => import("@/components/page/order.vue"),
      leftnav:() =>import("@/components/page/nav.vue"),
    },
    meta: {
			title: 'My Order - Fineshare Account Center'
		}
  },
  {//用户产品
    path: "/product",
    name:"product",
    meta: { keepAlive: true },
    components:{
      default:() => import("@/components/page/product.vue"),
      leftnav:() =>import("@/components/page/nav.vue"),
    },
    meta: {
			title: 'My Product - Fineshare Account Center'
		}
  },
  {
    path: "/details",
    name:"details",
    components:{
        default:() => import("@/components/page/details.vue"),
        leftnav:() =>import("@/components/page/nav.vue"),
      }
  },
  {
    path: "/loginweb",
    name:"loginweb",
    components:{
        default:() => import("@/components/login/loginweb.vue"),
        leftnav:() =>import("@/components/page/nav.vue"),
      },
      meta: {
        title: 'Fineshare Account Center'
      }
  },
  {//兑换会员资格
    path: "/redeem",
    name:"redeem",
    components:{
      default:() => import("@/components/page/redeem.vue"),
      leftnav:() =>import("@/components/page/nav.vue"),
    },
    meta: {
			title: 'Redeem - Fineshare Account Center'
		}
  },
  {//配置访问错误页面
    path:"/:pathMatch(.*)",
    components:{
      default:() => import("@/components/page/notfound.vue"),
      leftnav:() =>import("@/components/page/nav.vue"),
    },
    meta: {
			title: 'Fineshare Account Center'
		}
  }
]

//4,创建路由对象
const router = createRouter({
  routes,
  mode: 'hash', 
  history: createWebHistory()
})

//路由守卫
const authenticatedRoutes = ['/home', '/order','/account','/product','/redeem'];//需要守卫的路径
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Default Title';

  // 动态添加 meta 标签
const descriptionMeta = document.querySelector('meta[name="description"]');
  if (descriptionMeta) {
    descriptionMeta.setAttribute('content', to.meta.description || 'Default Description');
  } else {
    const meta = document.createElement('meta');
    meta.setAttribute('name', 'description');
    meta.setAttribute('content', to.meta.description || 'Default Description');
    document.head.appendChild(meta);
  }

  if (authenticatedRoutes.includes(to.path)) {
    const isAuthenticated = checkCookie('_FINESHARE_ID'); //检查用户是否登录；
    if (isAuthenticated) {
      next();
    } else {
      next('/login');
    }
  } else {
    next();
  }
})

function checkCookie(name) {
  const cookieValue = document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`);
  return cookieValue ? cookieValue.pop() : '';
}


export default router


import '@/assets/main.css';
import 'ant-design-vue/dist/antd.css';
import 'ant-design-vue/dist/antd.less'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'//导入router
import store from './store';
import Antd from 'ant-design-vue';//导入ant
import 'virtual:svg-icons-register';//导入图标
import fsicon from '@/components/view/fsicon.vue';//导入图标
import topUser from '@/components/page/topuser.vue';//用户信息模块
import Nav from '@/components/page/nav.vue';//用户信息模块

const app = createApp(App);
app.config.productionTip = false;//生成环境下不提示错误

app.use(Antd);
app.use(router);
app.use(store);
app.component('fsicon', fsicon);
app.component('topUser', topUser);
app.component('Nav', Nav);
app.mount('#app');



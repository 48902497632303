<template>
<section class="left-menu">
<div class="fs-logo"><a href="https://www.fineshare.com/" target="_blank"><fsicon :name="'fs-logo'" height="60"/></a></div>
  <a-menu v-model:selectedKeys="selectedKeys" class="leftnav">
      <a-menu-item key="1">
        <router-link to="/home">
          <HomeOutlined class="nav-icon"/>
          <span>Home</span>
         </router-link>
      </a-menu-item>
      <a-menu-item key="2" >
        <router-link to="/account">
          <UserOutlined class="nav-icon"/>
          <span>Account</span>
         </router-link>
      </a-menu-item>
    <a-menu-item key="3">
      <router-link to="/order">
        <ShoppingOutlined class="nav-icon top2"/>
        <span>Order</span>
      </router-link>
    </a-menu-item>
    <a-menu-item key="4">
      <router-link to="/product">
        <AppstoreOutlined class="nav-icon top2"/>
        <span>Product</span>
      </router-link>
    </a-menu-item>
    <a-menu-item key="5">
      <router-link to="/redeem">
        <crown-outlined class="nav-icon top2"/>
        <span>Redeem</span>
      </router-link>
    </a-menu-item>
  </a-menu>
  <div class="nav-bottom">
    <a href="https://www.fineshare.com/support/" target="_blank"><question-circle-outlined /> Help center</a>
  </div>
</section>
</template>
<script>
import {HomeOutlined,UserOutlined,ShoppingOutlined,AppstoreOutlined,CrownOutlined,QuestionCircleOutlined} from '@ant-design/icons-vue';
import { defineComponent, ref } from 'vue';
export default
defineComponent({
  components: {
    HomeOutlined,
    UserOutlined,
    ShoppingOutlined,
    AppstoreOutlined,
    CrownOutlined,
    QuestionCircleOutlined,
  },
  data() {
    return {
      url:"",
    };
  },
  methods: {
    getnav:function(){
      this.url=this.$route.path;
      console.log(url)
    }
  },
  setup() {
    const navlist= ["home","account","order","product","redeem"];
    const selectedKeys = ref([]);
    const collapsed = ref(false);
    const currentPath = window.location.pathname;
    const index = navlist.findIndex(item => currentPath.includes(item))+1;
    if (index !== -1) {
      selectedKeys.value = [String(index)];
    }

    return {
      selectedKeys,
      collapsed,
    };
  },
});
</script>
<style>
.left-menu{
  width: 100%;
  height: 100%;
  background: #fff;
  position: relative;
  }
.fs-logo{
  display: flex;
  justify-content: center;
  align-items: center;
}
.fs-logo a{
  padding: 0;
  display: block;
}
.fs-logo a svg{
  position: relative;
  left: -30px;
}
.leftnav{
  margin: 0;
  padding: 0 30px;
  border: none!important;
}
.leftnav li{
  margin: 18px 0!important;
}
.leftnav a{
   color: #000; 
   font-size: 16px;
   display: block;
   width: 100%;
  }
.leftnav a:hover{
  color: var(--vt-c-text-Orange);
}
.nav-icon svg{
  font-size:22px;
}
.ant-menu-item .ant-menu-item-only-child{
  margin: 14px 0;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
  border-radius: 8px;
  font-weight:bold!important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a{
  color: var(--vt-c-text-Orange)!important;
}
.top1{
  position: relative;
  top: 1px;
}
.top2{
  position: relative;
  top: 2px;
}
.nav-bottom{
  text-align: center;
  bottom: 0;
  left: 0;
  font-size: 16px;
  width: 100%;
  padding: 8px 0;
  position: absolute;
}
</style>